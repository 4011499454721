exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-components-about-js": () => import("./../../../src/pages/home/components/About.js" /* webpackChunkName: "component---src-pages-home-components-about-js" */),
  "component---src-pages-home-components-hero-js": () => import("./../../../src/pages/home/components/Hero.js" /* webpackChunkName: "component---src-pages-home-components-hero-js" */),
  "component---src-pages-home-components-keep-touch-js": () => import("./../../../src/pages/home/components/KeepTouch.js" /* webpackChunkName: "component---src-pages-home-components-keep-touch-js" */),
  "component---src-pages-home-components-portfolio-js": () => import("./../../../src/pages/home/components/Portfolio.js" /* webpackChunkName: "component---src-pages-home-components-portfolio-js" */),
  "component---src-pages-home-components-sample-text-js": () => import("./../../../src/pages/home/components/SampleText.js" /* webpackChunkName: "component---src-pages-home-components-sample-text-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

